import { Button, Col, Divider, Row, DatePicker, Card, Table, Select, Spin, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { logdata_request, MSM_EventLog_request, MSM_logdata_request } from '../../api'
import { C3Model, C3ModelMap, G2Model, MSMTable, DummyData } from '../../DataRequest/DataModel'
import {
    ConvertMonentToDateTime, EventSortTimeMSM, GetDateTime,
    DataSortTimeMSM, LogDataMappingTime, LogDataMappingTimeMSM,
    LogDataMappingNameMSM
} from '../../DataRequest/LogDataRequestWrap'
import { Line, Column } from '@antv/g2plot';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";


let DTinitial = GetDateTime(0, 1)  // get from yesterday 00:00 to today 00:00
let DTinitial2 = GetDateTime(0, 1)  // get from yesterday 00:00 to today 00:00
let DTinitial3 = GetDateTime(7, 1)  // get from yesterday 00:00 to today 00:00


let RequestDataOption = ['Line50kg', 'Line1kg', 'Line2kg', 'Line1Ton']
let RequestDateRange = [DTinitial[0], DTinitial[1]]

let BarChartRange = [DTinitial3[0], DTinitial3[1]]

let responseSorted = []
const annotations = [];

let EventLogRequestRange = [DTinitial2[0], DTinitial2[1]]
let EventLogSorted = []



const Dailydata = [
    {
        DateTime: '24/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '25/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '26/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '27/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '28/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '29/5/2022',
        type: 'Line50kg',
        Value: 245,
    },
    {
        DateTime: '30/5/2022',
        type: 'Line50kg',
        Value: 245,
    },


    {
        DateTime: '24/5/2022',
        type: 'Line1kg',
        Value: 543,
    },
    {
        DateTime: '25/5/2022',
        type: 'Line1kg',
        Value: 463,
    },
    {
        DateTime: '26/5/2022',
        type: 'Line1kg',
        Value: 234,
    },
    {
        DateTime: '27/5/2022',
        type: 'Line1kg',
        Value: 54,
    },
    {
        DateTime: '28/5/2022',
        type: 'Line1kg',
        Value: 643,
    },
    {
        DateTime: '29/5/2022',
        type: 'Line1kg',
        Value: 76,
    },
    {
        DateTime: '30/5/2022',
        type: 'Line1kg',
        Value: 876,
    },


    {
        DateTime: '24/5/2022',
        type: 'Line2kg',
        Value: 543,
    },
    {
        DateTime: '25/5/2022',
        type: 'Line2kg',
        Value: 463,
    },
    {
        DateTime: '26/5/2022',
        type: 'Line2kg',
        Value: 234,
    },
    {
        DateTime: '27/5/2022',
        type: 'Line2kg',
        Value: 54,
    },
    {
        DateTime: '28/5/2022',
        type: 'Line2kg',
        Value: 643,
    },
    {
        DateTime: '29/5/2022',
        type: 'Line2kg',
        Value: 76,
    },
    {
        DateTime: '30/5/2022',
        type: 'Line2kg',
        Value: 876,
    },


    {
        DateTime: '24/5/2022',
        type: 'Line1Ton',
        Value: 564,
    },
    {
        DateTime: '25/5/2022',
        type: 'Line1Ton',
        Value: 23,
    },
    {
        DateTime: '26/5/2022',
        type: 'Line1Ton',
        Value: 64,
    },
    {
        DateTime: '27/5/2022',
        type: 'Line1Ton',
        Value: 76,
    },
    {
        DateTime: '28/5/2022',
        type: 'Line1Ton',
        Value: 23,
    },
    {
        DateTime: '29/5/2022',
        type: 'Line1Ton',
        Value: 46,
    },
    {
        DateTime: '30/5/2022',
        type: 'Line1Ton',
        Value: 86,
    },
]


const DailyLogTable = [

]

const headersDaily = [
    { label: "DateTime", key: "DateTime" },
    { label: "Line50kg", key: "Line50kg" },
    { label: "Line1kg", key: "Line1kg" },
    { label: "Line2kg", key: "Line2kg" },
    { label: "Line1Ton", key: "Line1Ton" },
];

const headersEvent = [
    { label: "DateTime", key: "DateTime" },
    { label: "Tag", key: "Tag" },
    { label: "IdUnit", key: "IdUnit" },
    { label: "Label", key: "Label" },
    { label: "Weight", key: "Weight" },
    { label: "Location", key: "Location" },

];



const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography


let line, stackedColumnPlot

const EnergyUsage = () => {

    const [state, setState] = useState({ count: 0 });
    const [Loading, setLoading] = useState(false);
    const [sortedInfo, setSortedInfo] = useState({});
    const [sortedInfo2, setSortedInfo2] = useState({});


    const LogColumbPerTime = [
        {
            title: 'DateTime',
            width: 40,
            dataIndex: 'DateTime',
            key: 'DateTime',
            align: 'center',

            sorter: (a, b) =>
                new Date(moment(a.DateTime, "YYYY/MM/DD hh:mm:ss ").format("LLL")) -
                new Date(moment(b.DateTime, "YYYY/MM/DD hh:mm:ss ").format("LLL")),

            sortOrder: sortedInfo2.columnKey === 'DateTime' ? sortedInfo2.order : null,
            ellipsis: true,
        },
        {
            title: 'Tag',
            width: 60,
            dataIndex: 'Tag',
            key: 'Tag',
            align: 'center',
        },
        {
            title: 'Id Unit',
            width: 20,
            dataIndex: 'IdUnit',
            key: 'IdUnit',
            align: 'center',

            sorter: (a, b) => a.IdUnit - b.IdUnit,
            sortOrder: sortedInfo2.columnKey === 'IdUnit' ? sortedInfo2.order : null,
            ellipsis: true,
        },
        {
            title: 'Label',
            width: 20,
            dataIndex: 'Label',
            key: 'Label',
            align: 'center',

            filters: [
                {
                    text: 'Line50kg',
                    value: 'Line50kg',
                },
                {
                    text: 'Line1kg',
                    value: 'Line1kg',
                },
                {
                    text: 'Line2kg',
                    value: 'Line2kg',
                },
                {
                    text: 'Line1Ton',
                    value: 'Line1Ton',
                },
            ],
            onFilter: (value, record) => record.Label.indexOf(value) === 0,
        },

        {
            title: 'Weight',
            width: 20,
            dataIndex: 'Weight',
            key: 'Weight',
            align: 'center',
        },
        {
            title: 'Location',
            width: 20,
            dataIndex: 'Location',
            key: 'Location',
            align: 'center',
        },
    ]

    const handleChangeOverall = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo(sorter);
    };

    const handleChangePertime = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setSortedInfo2(sorter);
    };

    const EvenLogRequest = async () => {
        let response = await MSM_EventLog_request(RequestDataOption, EventLogRequestRange[0], EventLogRequestRange[1])
        //  let DailyLogSorted = LogDataMappingTimeMSM(MSMTable,response)   
        EventLogSorted = EventSortTimeMSM(response)
        console.log(EventLogSorted);
        setState(({ count }) => ({ count: count + 1 }));
    }

    useEffect(() => {
        console.log('Reffect');

        stackedColumnPlot = new Column('container', {
            data: responseSorted,
            isStack: true,
            xField: 'DateTime',
            yField: 'Value',
            seriesField: 'type',
            label: {
                position: 'middle',
                layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                ],
            },
            annotations,
        });

        BarChartInit()


        try { stackedColumnPlot.render(); }
        catch { }

        return () => {
            stackedColumnPlot.destroy()
        }
    }, [])

    // const UpdateTableData = async (Parameter, StartTime, EndTime, Site, Line, Page, BufferName, Model) => {
    //     console.log(Parameter)
    //     const response = await logdata_request(Parameter, StartTime, EndTime, Site, Line, Page)
    //     let data = DataSortTime(response)
    //     LogValue[BufferName] = data
    //     console.log(data);
    //     setState(({ count }) => ({ count: count + 1 }));
    // }

    const BarChartInit = async () => {
        // setLoading(true)
        // console.log(Parameter)
        let response = await MSM_logdata_request(['Line50kg', 'Line1kg', 'Line2kg', 'Line1Ton'], BarChartRange[0], BarChartRange[1])
        let BarChartData = DataSortTimeMSM(response)

        stackedColumnPlot.changeData(BarChartData)
        console.log(BarChartData);
        // setLoading(false)
        setState(({ count }) => ({ count: count + 1 }));
    }


    const LogColumb = [
        {
            title: 'DateTime',
            width: 70,
            dataIndex: 'DateTime',
            key: 'DateTime',
            align: 'center',

            sorter: (a, b) =>
                new Date(moment(a.DateTime, "YYYY/MM/DD hh:mm:ss ").format("LLL")) -
                new Date(moment(b.DateTime, "YYYY/MM/DD hh:mm:ss ").format("LLL")),

            sortOrder: sortedInfo.columnKey === 'DateTime' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: '50 kG',
            width: 70,
            dataIndex: 'Line50kg',
            key: 'Line50kg',
            align: 'center',

            sorter: (a, b) => a.Line50kg - b.Line50kg,
            sortOrder: sortedInfo.columnKey === 'Line50kg' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: '1 kG',
            width: 70,
            dataIndex: 'Line1kg',
            key: 'Line1kg',
            align: 'center',

            sorter: (a, b) => a.Line1kg - b.Line1kg,
            sortOrder: sortedInfo.columnKey === 'Line1kg' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: '2 kG',
            width: 70,
            dataIndex: 'Line2kg',
            key: 'Line2kg',
            align: 'center',

            sorter: (a, b) => a.Line2kg - b.Line2kg,
            sortOrder: sortedInfo.columnKey === 'Line2kg' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: '1 Ton',
            width: 70,
            dataIndex: 'Line1Ton',
            key: 'Line1Ton',
            align: 'center',

            sorter: (a, b) => a.Line1Ton - b.Line1Ton,
            sortOrder: sortedInfo.columnKey === 'Line1Ton' ? sortedInfo.order : null,
            ellipsis: true,
        },

    ]

    const DailyLogRequest = async () => {
        let response = await MSM_logdata_request(RequestDataOption, RequestDateRange[0], RequestDateRange[1])
        //  let responseSorted = LogDataMappingTimeMSM(MSMTable,response)   
        responseSorted = LogDataMappingNameMSM(MSMTable, DataSortTimeMSM(response))
        console.log(responseSorted);
        setState(({ count }) => ({ count: count + 1 }));
    }

    const ChangeDateRange = (value, type) => {

        switch (type) {
            case 'Daily':
                RequestDateRange = []
                RequestDateRange = ConvertMonentToDateTime(value)
                break;

            case 'Event':
                EventLogRequestRange = []
                EventLogRequestRange = ConvertMonentToDateTime(value)
                break;

            default:
                break;
        }




        // console.log(RequestDateRange);
    }


    const ChangeOption = (value) => {

        if (value === 'All') {
            RequestDataOption = ['Line50kg', 'Line1kg', 'Line2kg', 'Line1Ton']
        }

        else {
            console.log(`selected ${value}`);
            RequestDataOption = []
            RequestDataOption.push(value)
        }
    }

    return (
        <div>
            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Daily Count Log</Divider>
            </div>


            {/* <Spin tip="Loading..." spinning={Loading} size="large"> */}
                <Card title={'Weekly Record'} bordered={true} style={{ width: '100%', borderRadius: 15, backgroundColor: '#ffffff' }} hoverable={true}>
                    <div id='container'></div>
                </Card>
            {/* </Spin> */}


            <div style={{ paddingBottom: '10px' }}>
                <Divider orientation="left">Daily Log</Divider>
            </div>

            <div style={{ paddingBottom: '10px' }}>
                <RangePicker onChange={(e) => ChangeDateRange(e, 'Daily')} />

                <Button type='primary' style={{ marginLeft: '10px' }} >
                    <CSVLink data={responseSorted} target="_blank" filename={"DataLog.csv"} headers={headersDaily}>
                        Export
                    </CSVLink>
                </Button>

                <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { DailyLogRequest() }}>Update</Button>
            </div>


            <Card title='Daily Log' bordered={true}
                style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                <Table bordered columns={LogColumb} dataSource={responseSorted} size="small" scroll={{ y: 500 }}
                    onChange={handleChangeOverall} pagination={false} />
            </Card>

            <div style={{ marginTop: '16px' }}>

                <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Divider orientation="left">Event Log</Divider>
                </div>

                <div style={{ paddingBottom: '10px' }}>
                        <RangePicker onChange={(e) => ChangeDateRange(e, 'Event')} />

                        <Select defaultValue="Line50kg" style={{ width: 100, marginLeft: '10px' }} onChange={ChangeOption}>
                            <Option value="All">All</Option>
                            <Option value="Line50kg">Line50kg</Option>
                            <Option value="Line1kg">Line1kg</Option>
                            <Option value="Line2kg">Line2kg</Option>
                            <Option value="Line1Ton">Line1Ton</Option>
                        </Select>

                        <Button type='primary' style={{ marginLeft: '10px' }} >
                            <CSVLink data={EventLogSorted} target="_blank" filename={"EventLog.csv"} headers={headersEvent}>
                                Export
                            </CSVLink>
                        </Button>



                        <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { EvenLogRequest() }}>Update</Button>
                    </div>

                <Card title='Event Log' bordered={true}
                    style={{ width: '100%', borderRadius: 15, marginBottom: '10px' }} hoverable={true}>
                    <Table bordered columns={LogColumbPerTime} dataSource={EventLogSorted} scroll={{ x: 600, y: 350 }} pagination={false} size="small" onChange={handleChangePertime} />
                </Card>

            </div>



        </div>
    )
}

export default EnergyUsage

