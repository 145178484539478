import React from 'react'
import NotificationC3 from './NotificationC3'
import NotificationG2 from './NotificationG2'

const NotificationLine1 = () => {
    return (
        <div>
            <NotificationC3/>
            <NotificationG2/>
        </div>
    )
}

export default NotificationLine1